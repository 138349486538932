import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const WorkCollaboration = ({content, title, text, grey}) => {
    return (
        <section className={`${grey ? 'bg-[#FAFBFB]' : 'bg-[#121212]'} lg:py-[200px] md:py-[120px] py-[80px] w-[100%] relative overflow-hidden box-border`}>
            <StaticImage placeholder="none" src='../../images/guides-colaboration.webp' alt='guides' className='z-[0] guides-collaboration top-[-50px] right-0 md:top-[unset] w-[145%] lg:w-[auto] bottom-[unset] lg:bottom-[unset] md:bottom-0 lg:top-[0] lg:right-[0] md:right-[-250px] !absolute' />
            <div className={`container ${grey !== undefined ? '' : 'text-white'} z-[1] relative`}>
                <h2 className={`mb-[20px] lg:mb-[40px] font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] ${grey ? '' : 'lg:max-w-[810px]'}`} dangerouslySetInnerHTML={{ __html: title }}></h2>
                {
                    text && <p className='lg:text-[18px] lg:mb-[100px] md:mb-[80px] mb-[60px] md:text-[16px] text-[15px] leading-[26px] md:leading-[30px] tracking-[.02em] md:tracking-[.01em] lg:w-[800px] pr-[10px] lg:pr-[0]'>{text}</p>
                }
                <div className='flex flex-col lg:flex-row lg:gap-[60px] md:gap-[50px] gap-[40px]'>
                    {content.map((data, index) => (
                        <div key={index} className='lg:max-w-[calc(33%-40px)] pr-[10px] lg:pr-[0]'>
                            <h3 className='mb-[10px] leading-[27px] font-medium font-heading lg:leading-[55px] text-[20px] md:text-[28px] lg:text-[35px] md:leading-[40px]'>{data.title}</h3>
                            <p className='text-[15px] leading-[26px] font-light md:text-[16px] lg:text-[18px] lg:leading-[30px] tracking-[.02em] md:tracking-[.03em]'>{data.content}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
export default WorkCollaboration
