import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import WorkCollaboration from "../components/about/WorkCollaboration";
import Cta from "../components/service/Cta";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import Banner from "../components/Banner";
const Process = () => {
  const banner = {
    title: `Flexible Process <br /> With Business Goals`,
    content:
      "Every company has its own working module & to introduce a change becomes a task. To ease the process we provide a flexible yet promising UX UI process that will aid and align with your company.",
  };
  const simpleList = [
    {
      title: "1. Listening And Understanding",
      des: "Let's schedule a date to understand your product and the challenges you are currently facing. This will help define the nature of our collaboration.",
    },
    {
      title: "2. Identifying The Challenges",
      des: "Exploring and identifying your market to interviewing or ask for feedback through various methods will help gain a clear idea about the problem.",
    },
    {
      title: "3. UX Strategy & Information Architecture",
      des: "Planning the UX flow, creating guidelines & structuring information Architecture to plan on the information, resources and its placing to attain the goal.",
    },
    {
      title: "4. Sketching And Wire-Framing Process",
      des: "Producing the visuals of the idea for an app and it's utilities, creating elements and hierarchy. This will help understand the function and steps of the app.",
    },
    {
      title: "5. UI Design, Prototype & Testing",
      des: "This is the most exciting part. Once the layout and designs are confirmed, a prototype is created to test how the final product will look and work.",
    },
  ];
  const content = [
    {
      title: "Business Analysts",
      content:
        "Our analysts understand your business, conduct market research and foresee business challenges to provide cost-effective solutions. ",
    },
    {
      title: "Designers",
      content:
        "Our experienced designers craft digital offerings with eye-catching elements that improve your user experience and engagement.",
    },
    {
      title: "Developers",
      content:
        "Our developers prioritise heuristics and efficiently transform designs into pixel-perfect UIs using suitable technology stacks and libraries.",
    },
  ];
  const colTitle = `The Best Way To Solve Complex Problems Is To Work In Collaboration`;
  const cta = {
    title: "Let’s Take Your Product <br /> to Newer Heights",
  };
  const providingData = [
    {
      title: "Requirement Based",
      des: "Do you have defined/identified challenges? This model will help in delivering the result faster as we will directly work for the solutions.",
    },
    {
      title: "Dedicated Teams",
      des: "We offer a team of dedicated experts to work on your project with all the necessary equipment and infrastructure.",
    },
    {
      title: "On-Demand (Hourly)",
      des: "If you are unsure of the requirements or require help for unforeseen challenges. Our team will work with you on-demand with minimum TAT.",
    },
    {
      title: "Consultation",
      des: "If you have a team set-up and all you need is a mentor or guidance or validator throughout the project then you can consult our experts.",
    },
  ];
  return (
    <Layout>
      <div className="service-page">
        <Banner content={banner} page={"service"} />
        <section className="lg:py-[200px] md:py-[120px] py-[80px] bg-[#FAFBFB]">
          <div className="container">
            <h2 className="mb-[40px] md:mb-[60px] text-[38px] leading-[51px] md:text-[50px] font-heading font-medium lg:text-[60px] md:leading-[68px] lg:leading-[84px] tracking-[.08em] lg:tracking-[.01em]">
              Flexible Lean & Agile <br /> Process
            </h2>
            <StaticImage src="../images/process-section.webp" alt="process" />
          </div>
        </section>
        <section className="lg:py-[200px] md:py-[120px] py-[80px] relative">
          <div className="container">
            <div className="lg:mb-[100px] md:mb-[80px] mb-[60px]">
              <h2 className="mb-[40px] md:mb-[60px] text-[38px] leading-[51px] md:text-[50px] font-heading font-medium lg:text-[60px] md:leading-[68px] lg:leading-[84px] tracking-[.08em] lg:tracking-[.01em]">
                Simple Methods & <br /> Great Execution
              </h2>
              <p className="md:tracking-[.03em] tracking-[.02em] faq-des md:text-[16px] md:leading-[1.8750] text-[15px] lg:text-[18px] lg:leading-[1.67] leading-[1.733] lg:max-w-[538px] md:max-w-[668px]">
                With our customised strategies combined with a proactive
                approach we bring remarkable results. Our team leverages their
                expertise to flawlessly execute every aspect of the project and
                bring your ideas to life in the most effective and impactful way
                possible.
              </p>
            </div>
            <div className="flex lg:pr-[100px] md:pr-[20px] pr-[12px] flex-col md:gap-[50px] gap-[40px]">
              {simpleList.map((item, index) => (
                <div key={index}>
                  <h3 className="lg:text-[35px] md:text-[22px] text-[20px] tracking-[.08em] font-medium lg:tracking-[.06em] font-heading leading-[1.3181] lg:leading-[1.5714] mb-[10px]">
                    {item.title}
                  </h3>
                  <p className="md:tracking-[.03em] tracking-[.02em] md:text-[16px] md:leading-[1.8750] text-[15px] lg:text-[18px] lg:leading-[1.67] leading-[1.733]">
                    {item.des}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="lg:py-[200px] md:py-[120px] py-[80px] bg-[#FAFBFB]">
          <div className="container">
            <h2 className="mb-[60px] md:mb-[80px] lg:mb-[50px] text-[38px] leading-[51px] md:text-[50px] font-heading font-medium lg:text-[60px] md:leading-[68px] lg:leading-[84px] tracking-[.08em] lg:tracking-[.01em]">
              Solving Through <br /> Competitive
            </h2>
            <div className="flex md:gap-[50px] gap-[40px] flex-col-reverse">
              <div className="flex lg:flex-row flex-col md:gap-[50px] gap-[40px] lg:pr-[20px]">
                <div>
                  <h3 className="lg:text-[35px] lg:leading-[55px] tracking-[.06em] font-medium font-heading md:text-[28px] md:leading-[40px] text-[20px] leading-[normal]">
                    Focus On Customer-Centricity And Follow Best Practices
                  </h3>
                  <p className="leading-[26px] md:text-[16px] lg:text-[18px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em]">
                    Devise a strategy that generates the result that aligns
                    business with consumer's need by following the practices
                    that are best suited.
                  </p>
                </div>
                <div>
                  <h3 className="lg:text-[35px] lg:leading-[55px] tracking-[.06em] font-medium font-heading md:text-[28px] md:leading-[40px] text-[20px] leading-[normal]">
                    Monitor To Oversee Ongoing Strategy And Execution
                  </h3>
                  <p className="leading-[26px] md:text-[16px] lg:text-[18px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em]">
                    Even after the final product is hand over, we monitor the
                    ongoing strategy and manage the execution till we you are
                    satisfied.
                  </p>
                </div>
              </div>
              <div className="w-[100%]">
                <StaticImage
                  src="../images/solve-process.webp"
                  alt="solve-process"
                />
              </div>
            </div>
          </div>
        </section>
        <WorkCollaboration content={content} title={colTitle} />
        <section className="about-octet lg:py-[200px] md:py-[120px] py-[80px]">
          <div className="container">
            <h2 className="lg:mb-[30px] mb-[20px] font-medium font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.08em] lg:max-w-[715px]">
              Providing A Flexible Engagement Model
            </h2>
            <p className="text-[15px] leading-[26px] md:text-[16px] lg:text-[18px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em] lg:w-[538px] lg:mb-[100px] md:mb-[80px] mb-[60px] md:pr-[15px] pr-0">
              We encourage collaboration with our flexible engagement model
              where you can meet your unique business requirements and budget
              constraints of individual projects. Our aim is to offer a
              strategic advantage by fostering a strong and efficient
              partnership.
            </p>
            <div
              className={`flex justify-between flex-col gap-[40px] md:gap-[50px]`}
            >
              <div className="w-[100%]">
                <StaticImage
                  src="../images/providing-process.webp"
                  alt="providing-process"
                />
              </div>
              <div className="flex flex-col lg:flex-row lg:flex-wrap md:gap-[50px] gap-[40px]">
                {providingData.map((item, index) => (
                  <div key={index} className="lg:max-w-[calc(50%-25px)]">
                    <h3 className="lg:w-[591px] mb-[10px] md:text-[28px] font-medium text-[20px] leading-[normal] md:leading-[40px] lg:pr-[0] md:pr-[40px] pr-[10px] lg:text-[35px] lg:leading-[55px] tracking-[.05em] lg:tracking-[.06em] font-heading">
                      {item.title}
                    </h3>
                    <p
                      className={`leading-[26px] md:text-[16px] lg:text-[18px] md:leading-[30px] tracking-[.02em] md:tracking-[.03em]`}
                    >
                      {item.des}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <Cta data={cta} />
        {/* <KnowMoreSection /> */}
      </div>
    </Layout>
  );
};
export default Process;

export const Head = () => (
  <Seo
    title="Stages of UI UX Process at Octet | UI UX Design Process"
    description="Get to know about our flexible UI UX process that aligns your business goals with your end-users needs. Visit our website and explore more about us."
  />
);
